<template>
    <div class="modal-complete-profile">
        <p class="m-b-8 f-14 f-regular sub-title">프로필이 새로워졌어요!</p>
        <p class="m-b-20 f-24 f-medium c-black">프로필을 완성해 주세요!</p>
        <div class="btns">
            <div class="btn" @click="goToEditProfile">프로필 완성하기</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalCompleteProfile',
    props: ['options'],
    data: () => ({
        step: 0,
    }),
    computed: {},
    methods: {
        goToEditProfile() {
            this.$emit('close', 'goToEditProfile')
        },
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Popup_ProfileAdd',
            },
        })
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#372E66',
                bottom: '#FFFFFF',
            },
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#8A74FF',
                bottom: '#FFFFFF',
            },
        })
    },
}
</script>

<style lang="scss" scoped>
.modal-complete-profile {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .sub-title {
        color: $grey-08;
    }

    .btns {
        width: 100%;

        .btn {
            position: relative;
            width: 100%;
            height: 48px;
            font-size: 15px;
            color: white;
            background-image: linear-gradient(to right, #ba90ff, $purple-primary);
            border-radius: 8px;
            @include f-medium;
        }
    }
}
</style>
